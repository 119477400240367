import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';

import { SimpleLoyaltyAccount } from '../../types/domain';

export const toSimpleAccount = (account: LoyaltyAccount): SimpleLoyaltyAccount => {
  const result: SimpleLoyaltyAccount = {
    id: account.id,
    contactId: account.contactId,
    memberId: account.memberId,
    pointsEarned: account.points?.earned,
    pointsBalance: account.points?.balance,
    pointsRedeemed: account.points?.redeemed,
    pointsAdjusted: account.points?.adjusted,
    lastActionDescription: account.latestTransaction?.description,
    lastTransactionId: account.latestTransaction?.id,
    tierId: account.tier?.id,
    tiersPeriodPoints: account.tier?.points,
    pointsExpirationAmount: account.pointsExpiration?.expiringPointsAmount,
    pointsExpirationDate: account.pointsExpiration?.expirationDate,
  };

  return result;
};
